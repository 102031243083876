<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
      <div class="wrapper" v-if="blacklist.length">
        <div class="box-wrapper container pt-2 pb-2" v-for="(person, key) in blacklist" :key="key">
        <div class="profile-status-avatar-spinner">
            <div class="profile-status-avatar-picture">
              <img :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')" alt="" />
            </div>
        </div>
          <div class="top-heading-medium text-center">{{person.username}}</div>
          <button class="btn-black-full w-100 mt-0 mb-1" @click="deleteFromBlacklist(person.id)">Odblokuj użytkownika</button>
        </div>
      </div>
      <div v-else>
        <div class="wrapper container pt-3 pb-3">
          <div class="top-heading-medium text-center">Brak zablokowanych użytkowników</div>
          </div>
        </div>
      </div>
      <div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
export default {
  name: 'SettingsBlockedUsers',
  components: {
    TopMenuSubheadline
  },
  data () {
    return {
      title: 'Zablokowani użytkownicy',
      avatar: 'profile-avatar-circle-full.jpg',
      blacklist: []
    }
  },
  created () {
    this.getBlacklist()
  },
  methods: {
    getBlacklist () {
      this.$https('/blacklist', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(({ data }) => {
          this.blacklist = data.response
        })
    },
    deleteFromBlacklist (id) {
      const getData = `user=${id}`
      this.$https('/blacklist', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.getBlacklist()
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper {
    margin-top: 55px;
    margin-bottom: 50px;
  }
  .box-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
  }
.profile-status-avatar-spinner .profile-status-avatar-picture {
  position: relative;
  top: 30px;
}
.profile-status-avatar-picture {
  box-shadow: none
}
.profile-status-avatar-spinner {
  margin: 40px 0 0 0;
}
</style>
